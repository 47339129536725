<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col>
                    <v-alert :dismissible="false" class="deep-purple white--text" dark border="left" icon="fas fa-chart-area">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10" class="pa-0">
                                <span>OKEx Markets</span>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="px-0 ml-3">
                    <v-tabs v-model="type" color="indigo" show-arrows next-icon="mdi-menu-right" prev-icon="mdi-menu-left">
                        <v-tab href="#okexSpot" class="text-none">OKEx Spot</v-tab>
                        <v-tab href="#okexBTCSpot" class="text-none">OKEx BTC Spot</v-tab>
                        <v-tab href="#okexCoinSwaps" class="text-none">OKEx Coin Swaps</v-tab>
                        <v-tab href="#okexUsdtSwaps" class="text-none">OKEx USDT Swaps</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="type">
                        <v-tab-item value="okexSpot">
                            <OkexSpot></OkexSpot>
                        </v-tab-item>
                        <v-tab-item value="okexBTCSpot">
                            <OkexBTCSpot></OkexBTCSpot>
                        </v-tab-item>
                        <v-tab-item value="okexCoinSwaps">
                            <OkexCoinSwaps></OkexCoinSwaps>
                        </v-tab-item>
                         <v-tab-item value="okexUsdtSwaps">
                            <OkexUsdtSwaps></OkexUsdtSwaps>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import {  mapGetters } from "vuex";
    import OkexSpot from './OkexSpot';
    import OkexBTCSpot from './OkexBTCSpot';
    import OkexCoinSwaps from './OkexCoinSwaps';
    import OkexUsdtSwaps from './OkexUsdtSwaps';
    export default {
        data() {
            return {
                type:"result",
                all:true,
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        components:{
            OkexSpot,
            OkexBTCSpot,
            OkexCoinSwaps,
            OkexUsdtSwaps
        },
        watch: {
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
        },
        created() {

        },
        methods: {
            
        }
    }
</script>
